

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import AddressForm from '@/components/AddressForm.vue';

import moment from 'moment';

export default defineComponent({
    components: { AddressForm },
    mixins: [Base],
    watch: {
        active() {
            this.getProducts();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            moment,
            isAddProductModal: false,
            params: {
                page: 1,
            },
            newProduct: {
                product: undefined,
                pivot: {
                    expiration_date: new Date(),
                    reminder_date: new Date(),
                },
            },
            items: {
                data: [],
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getProducts(page = 1) {
            this.params.page = page;
            this.get(`objects/${this.$route.params.id}/products`, {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
        addProduct() {
            this.post(`objects/${this.$route.params.id}/products`, {
                ...this.newProduct,
                product_id: this.newProduct.product ? this.newProduct.product.id : null,
            })
                .then(() => {
                    this.isAddProductModal = false;
                    this.getProducts();
                });
        },
        saveProduct(data: any) {
            this.put(`objects/${this.$route.params.id}/products/${data.id}`, {
                ...data,
            })
                .then(() => {
                    this.getProducts();
                });
        },
        deleteProduct(id) {
            this.delete(`objects/${this.$route.params.id}/products/${id}`)
                .then(({ data }) => {
                    this.getProducts();
                });
        },
    },

});
