

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active() {
            this.getItems();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isChangeOwnershipModal: false,
            newInfringement: {
                infringement: undefined,
                comment: null,
            },
            items: {
                data: [
                    { from: Date.now(), to: Date.now(), owner: { name: 'test' } },
                ] as Array<IObject>,
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getItems() {

        },
    },
});
