

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active() {
            this.getAttachments();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isAddAttachmentsModal: false,
            attachments: [],
            params: {
                page: 1,
            },
            items: {
                data: [
                    { name: 'test.pdf', type: 'pdf', size: 433243 },
                ] as Array<IObject>,
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getAttachments(page = 1) {
            this.params.page = page;
            this.get(`objects/${this.$route.params.id}/attachments`, {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
        addAttachments() {
            const form = new FormData();

            this.attachments.forEach((file) => {
                console.log(file);
                form.append('attachments[]', file);
            });

            this.post(`objects/${this.$route.params.id}/attachments`, form)
                .then(() => {
                    this.isAddAttachmentsModal = false;
                    this.getAttachments();
                });
        },
        deleteAttachment(id) {
            this.delete(`objects/${this.$route.params.id}/attachments/${id}`)
                .then(({ data }) => {
                    this.getAttachments();
                });
        },
    },
});
