

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import AddressForm from '@/components/AddressForm.vue';

export default defineComponent({
    components: { AddressForm },
    mixins: [Base],
    watch: {
        active() {
            this.getInfringements();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isAddInfringementModal: false,
            params: {
                page: 1,
            },
            newInfringement: {
                infringement: undefined,
                comment: '',
            },
            items: {
                data: [],
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getInfringements(page = 1) {
            this.params.page = page;
            this.get(`objects/${this.$route.params.id}/infringements`, {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
        addInfringement() {
            this.post(`objects/${this.$route.params.id}/infringements`, {
                ...this.newInfringement,
                infringement_id: this.newInfringement.infringement ? this.newInfringement.infringement.id : null,
            })
                .then(() => {
                    this.isAddInfringementModal = false;
                    this.getInfringements();
                });
        },
        deleteInfringement(id) {
            this.delete(`objects/${this.$route.params.id}/infringements/${id}`)
                .then(({ data }) => {
                    this.getInfringements();
                });
        },
    },

});
