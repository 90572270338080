

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active() {
            this.getComments();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isAddCommentModal: false,
            newComment: {
                comment: '',
            },
            params: {
                page: 1,
                orderBy: 'desc',
                sortBy: 'created_at',
            },
            items: {
                data: [
                    {
                        created_by: { name: 'John Doe' }, comment: 'test', created_at: Date.now(),
                    },
                ] as Array<IObject>,
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getComments(page = 1) {
            this.params.page = page;
            this.get(`objects/${this.$route.params.id}/comments`, {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
        addComment() {
            this.post(`objects/${this.$route.params.id}/comments`, {
                ...this.newComment,
            })
                .then(() => {
                    this.isAddCommentModal = false;
                    this.getComments();
                });
        },
        deleteComment(id) {
            this.delete(`objects/${this.$route.params.id}/comments/${id}`)
                .then(({ data }) => {
                    this.getComments();
                });
        },
    },
});
