

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import { QrStream } from 'vue3-qr-reader';

export default defineComponent({
    components: { 'qr-stream': QrStream },
    mixins: [Base],
    watch: {
        active() {

        },
    },
    data() {
        return {
            isStreamQRCodeModal: false,
        };
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },

});
