

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import AddressForm from '@/components/AddressForm.vue';

export default defineComponent({
    components: { AddressForm },
    mixins: [Base],
    watch: {
        active() {
            this.getLocations();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            isAddLocationModal: false,
            params: {
                page: 1,
            },
            newLocation: {
                street: '',
                number: '',
                box: '',
                postal_code: '',
                city: '',
                country: '',
                region: '',
                lat: '',
                lng: '',
                from_date: new Date(),
            },
            items: {
                data: [],
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getLocations(page = 1) {
            this.params.page = page;
            this.get(`objects/${this.$route.params.id}/locations`, {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
        addLocation() {
            this.post(`objects/${this.$route.params.id}/locations`, {
                ...this.newLocation,
            })
                .then(() => {
                    this.isAddLocationModal = false;
                    this.getLocations();
                });
        },
        deleteLocation(id) {
            this.delete(`objects/${this.$route.params.id}/locations/${id}`)
                .then(({ data }) => {
                    this.getLocations();
                });
        },
    },

});
