
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import General from '@/pages/objects/partials/General.vue';
import Locations from '@/pages/objects/partials/Locations.vue';
import Infringements from '@/pages/objects/partials/Infringements.vue';
import Comments from '@/pages/objects/partials/Comments.vue';
import Jobs from '@/pages/objects/partials/Jobs.vue';
import Ownerships from '@/pages/objects/partials/Ownerships.vue';
import Products from '@/pages/objects/partials/Products.vue';
import Attachments from '@/pages/objects/partials/Attachments.vue';

export default defineComponent({
    components: {
        Attachments,
        Ownerships,
        Jobs,
        Comments,
        Infringements,
        General,
        Locations,
        Products,
    },
    mixins: [Base],
    emits: ['reload'],
    data(): any {
        return {
            currentTab: 'general',
            item: undefined as any,
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`objects/${id}`)
                .then(({ data }: any) => {
                    this.item = data.data;
                    (this.$refs.form as any).setValues(this.item);
                });
        },
        saveItem(): void {
            this.put(`objects/${this.$route.params.id}`, {
                ...this.item,
                client_id: this.item.client ? this.item.client.id : null,
            })
                .then(() => {
                    this.$router.push({ name: 'objects.list' });
                    this.$emit('reload');
                });
        },
    },
});
