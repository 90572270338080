

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active() {
            this.getItems();
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            items: {
                data: [
                    { job_id: 223434, comment: 'ok', address: 'De Valk 40, 4342 Antwerpen' },
                ] as Array<IObject>,
                meta: {
                    total: 0,
                },
            },
        };
    },
    methods: {
        getItems() {

        },
    },
});
